export default async function ({ $auth, redirect, route, store }) {
  // 如果有 appAuth 參數，則將 appAuth 設定為使用者 token
  // 如參數為 ?appAuth=，則登出，如果為 ?appAuth，則維持登入狀態
  const { appAuth } = route.query
  try {
    if (appAuth) {
      await $auth.setUserToken(appAuth)
    }
    if (appAuth === '') {
      await store.dispatch('logOut')
    }
  } catch (err) {
    console.log(err)
    redirect(`/login?redirect=${route.path}`)
  }
}
