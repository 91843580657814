export const state = () => ({
  serviceData: {},
  allServiceList: [],
  starServiceList: []
})
export const getters = {
  getBannerList: state => id => {
    return state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].image_file?.length > 1 ? state.serviceData[parseInt(id)].image_file.slice(1) : []
  },
  redirection: state => id => {
    if (state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].Redirection !== undefined) {
      return state.serviceData[parseInt(id)].Redirection
    } else {
      return null
    }
  },
  optionPriceGroup: state => id => {
    if (state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].ServicePriceOptionGroup) {
      return state.serviceData[parseInt(id)].ServicePriceOptionGroup
    } else {
      return []
    }
  },
  optionGroup: state => id => {
    if (state.serviceData[parseInt(id)]?.ServiceOptionGroup) {
      const optionGroup = state.serviceData[parseInt(id)].ServiceOptionGroup
        .map(option => {
          let optionRequired = false
          let subTitle = ''
          switch (option.category_option) {
            case 2:
              optionRequired = true
              subTitle = '單選'
              break
            case 4:
              subTitle = '可複選'
              if (option.lowest_limit > 0) {
                optionRequired = true
              }
              break
            case 5:
              if (option.is_required) {
                subTitle = '必填'
                optionRequired = true
              } else {
                subTitle = '非必填'
              }
              break
          }
          option.required = optionRequired
          option.subTitle = subTitle
          return option
        })
      return optionGroup
    } else {
      return []
    }
  },
  regionList: state => id => {
    if (state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].CityAreaList) {
      return state.serviceData[parseInt(id)].CityAreaList
    } else {
      return []
    }
  },
  serviceDate: state => id => {
    if (state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].ServiceDate) {
      return state.serviceData[parseInt(id)].ServiceDate.filter(date => date.IsWork)
    } else {
      return []
    }
  },
  scoreList: state => id => {
    if (state.serviceData[parseInt(id)] && state.serviceData[parseInt(id)].ServiceScores) {
      return _.cloneDeep(state.serviceData[parseInt(id)].ServiceScores.scores)
    } else {
      return []
    }
  },
  householdApplianceSupplier: () => {
    switch (process.env.NODE_ENV_NAME) {
      case 'local':
        return [119]
      case 'staging':
        return [49, 25]
      case 'ec':
        return [49]
      default:
        break
    }
  },
  householdApplianceServiceTypeId: () => {
    switch (process.env.NODE_ENV_NAME) {
      case 'local':
        return [192, 110, 208]
      case 'staging':
        return [158, 227, 244, 121]
      case 'ec':
        return [158, 227, 244]
      default:
        break
    }
  }
}

export const mutations = {
  clearServiceData (state, { id }) {
    if (state.serviceData[parseInt(id)]) {
      delete state.serviceData[parseInt(id)]
    }
  },
  setServiceData (state, { id, Data }) {
    state.serviceData[parseInt(id)] = _.cloneDeep(Data)
  },
  setServiceRedirection (state, { id, Data }) {
    state.serviceData[parseInt(id)].Redirection = _.cloneDeep(Data)
  },
  setServicePriceOption (state, { id, Data }) {
    state.serviceData[parseInt(id)].ServicePriceOptionGroup = _.cloneDeep(Data)
  },
  setServiceOption (state, { id, Data }) {
    state.serviceData[parseInt(id)].ServiceOptionGroup = _.cloneDeep(Data)
  },
  setServiceDate (state, { id, Data }) {
    state.serviceData[parseInt(id)].ServiceDate = _.cloneDeep(Data)
  },
  setServiceTime (state, { id, Data }) {
    state.serviceData[parseInt(id)].ServiceTime = _.cloneDeep(Data)
  },
  setServiceScores (state, { id, Data }) {
    if (state.serviceData[parseInt(id)].ServiceScores) {
      state.serviceData[parseInt(id)].ServiceScores.scores.push(...Data.scores)
    } else {
      state.serviceData[parseInt(id)].ServiceScores = _.cloneDeep(Data)
    }
  },
  setAllServiceList (state, data) {
    state.allServiceList = _.cloneDeep(data)
  },
  setStarServiceList (state, data) {
    state.starServiceList = _.cloneDeep(data)
  }
}

export const actions = {
  async getServiceMetaTag ({ commit, state }, { id }) {
    const { Data } = await this.$axios.$get('/api/v2/Main/GetServiceMetaTag', { params: { ServiceId: id } })
      .catch(error => Promise.reject(error))
    return Data || ''
  },
  async getServiceInfoData ({ state }, { id }) {
    const { Data } = await this.$axios.$get('/api/v2/Main/GetMainDetail', { params: { service_id: id } })
    Data.content = Data.content.replace(/http:\/\//g, 'https://')
    Data.note = Data.note.replace(/http:\/\//g, 'https://')
    Data.price_plan = Data.price_plan.replace(/http:\/\//g, 'https://')
    Data.terms_content = Data.terms_content.replace(/http:\/\//g, 'https://')
    if (Data.service_ratings) {
      Data.service_ratings = Data.service_ratings.replace(/http:\/\//g, 'https://')
    }
    return Data
  },
  async getServiceInfo ({ commit, state, dispatch }, { id }) {
    if (!id) return null
    if (state.serviceData[parseInt(id)]) return state.serviceData[parseInt(id)]
    const Data = await dispatch('getServiceInfoData', { id })
    commit('setServiceData', { id, Data })
    return Data
  },
  async getServiceOptionData (_, { id }) {
    const { Data } = await this.$axios.$get('/api/Main/GetGroupDetailByHasPromotionTag', { params: { id } })
    const categoryList = Data.service_detail && Data.service_detail.length > 0 ? Data.service_detail[0].category : []
    const priceOption = categoryList.filter(category => category.category_option === 1)
    const optionList = categoryList.filter(category => [2, 4, 5].indexOf(category.category_option) > -1)
    return {
      priceOption,
      optionList
    }
  },
  async getServiceOption ({ commit, state, dispatch }, { id }) {
    const serviceOptionData = await dispatch('getServiceOptionData', { id })
    commit('setServicePriceOption', { id, Data: serviceOptionData.priceOption })
    commit('setServiceOption', { id, Data: serviceOptionData.optionList })
  },
  async getServiceDate ({ commit, state }, { id }) {
    if (!id ||
        !state.serviceData[parseInt(id)] ||
        !state.serviceData[parseInt(id)].serviceTimeType ||
        state.serviceData[parseInt(id)].serviceTimeType === 0) return // 無日期與時間設定
    const theDate = this.$dayjs().add(state.serviceData[parseInt(id)].delay_work_day, 'd').format('YYYY/MM/DD')
    const { Data } = await this.$axios.$get('/api/v2/ServiceCustomDates/Get30WorkingDays', { params: { ServiceId: id, Date: theDate } })
    commit('setServiceDate', { id, Data })
  },
  async getServiceTime ({ commit, state }, { id }) {
    if (!id ||
      !state.serviceData[parseInt(id)]) return
    const { Data } = await this.$axios.$get('/api/v2/Main/GetServiceTimes', { params: { ServiceId: id } })
    commit('setServiceTime', { id, Data })
  },
  async getSSRServiceScore ({ commit, state }, { id }) {
    if (!id ||
      !state.serviceData[parseInt(id)]) return
    try {
      const { Data } = await this.$axios.$get('/api/v1/SEO/GetScores/' + id)
      Object.keys(Data).forEach(key => {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1)
        Data[newKey] = Data[key]
        delete Data[key]
      })
      Data.scores = []
      commit('setServiceScores', { id, Data })
      return Data
    } catch (error) {
      console.error('Error fetching SSR service score:', error)
      return null
    }
  },
  async getServiceScores ({ commit, state }, { id, page = 1, preCount = 10 }) {
    if (!id ||
      !state.serviceData[parseInt(id)]) return
    const { Data } = await this.$axios.$get('/api/v2/Main/GetScores', {
      params: {
        service_id: id,
        Offset: (page - 1) * preCount,
        MaxCount: preCount
      }
    })
    commit('setServiceScores', { id, Data })
    return Data.scores
  },
  async getServiceRedirection ({ commit, state }, { id }) {
    if (!id ||
      !state.serviceData[parseInt(id)]) return
    let { Data } = await this.$axios.$get('/api/ServiceRedirection/GetList', { params: { service_id: id } })
    Data = Data || false
    commit('setServiceRedirection', { id, Data })
    return Data
  },
  async getServicesByParentType ({ dispatch }, { TypeParent }) {
    try {
      const postData = { TypeParent, IsWeb: true }
      const userDefaultCommunity = await dispatch('getUserDefaultCommunity', null, { root: true })
      if (userDefaultCommunity?.AreaId) {
        postData.RegionId = [userDefaultCommunity.AreaId]
        postData.DefaultCommunityId = userDefaultCommunity.Id
      } else {
        postData.RegionId = []
      }
      const { Data } = await this.$axios.$get('/api/v2/Main/GetServices', { params: postData, useCache: true })
      return Data
    } catch (err) {
      console.log('err', err)
      return Promise.reject(err)
    }
  },
  async getStarServiceList ({ commit, state, dispatch }, refresh = false) {
    try {
      if (state.starServiceList.length > 0 && !refresh) return state.starServiceList
      const postData = { IsWeb: true }
      if (this.$auth.loggedIn) {
        const userDefaultCommunity = await dispatch('getUserDefaultCommunity', null, { root: true })
        if (userDefaultCommunity.AreaId) {
          postData.RegionId = [userDefaultCommunity.AreaId]
          postData.DefaultCommunityId = userDefaultCommunity.Id
        } else {
          postData.RegionId = []
        }
      }
      const { Data } = await this.$axios.$get('/api/v2/StarService/GetStarServiceList', {
        params: postData,
        useCache: true
      })
      const starServiceList = Data.StarServiceList.map(service => {
        if (service && service.imgUri && service.imgUri.substring(0, 5) === 'http:') {
          service.imgUri = 'https:' + service.imgUri.substring(5)
        }
        return {
          id: service.StarServiceId,
          serviceId: service.ServiceId,
          typeParentId: service.TypeParentId || null,
          typeId: service.TypeId || null,
          typeName: service.TypeName || null,
          iconName: service.IconName || null,
          title: service.Title,
          tabName: '熱門服務',
          categoryId: 'star',
          imgUrl: service.imgUri,
          desc: service.Desc,
          hasPromotion: service.HasPromotion,
          serviceAddressMode: service.ServiceAddressMode, // 1:地址模式 2:社區模式
          minimumPriceOrPricingMethod: {
            content: service.MinimumPriceOrPricingMethod.Content,
            isNumber: service.MinimumPriceOrPricingMethod.IsNumber
          },
          ratingSummary: {
            totalRatings: service.RatingSummary.TotalRatings,
            averageRating: service.RatingSummary.AverageRating
          }
        }
      })
      return starServiceList
    } catch (err) {
      console.log('err', err)
      return err
    }
  },
  async getAllService ({ commit, state, dispatch }, refresh = false) {
    if (state.allServiceList.length > 0 && !refresh) return state.allServiceList
    const postData = { IsWeb: true }
    const userDefaultCommunity = await dispatch('getUserDefaultCommunity', null, { root: true })
    if (userDefaultCommunity?.AreaId) {
      postData.RegionId = [userDefaultCommunity.AreaId]
      postData.DefaultCommunityId = userDefaultCommunity.Id
    }
    const { Data } = await this.$axios.$get('/api/v2/Main/GetMainByRegionV2', {
      params: postData,
      useCache: true
    })
    const allServiceList = Data.sort((a, b) => a.serviceTypeSort - b.serviceTypeSort)
      .map(service => {
        if (service && service.bannerUrl && service.bannerUrl.substring(0, 5) === 'http:') {
          service.bannerUrl = 'https:' + service.bannerUrl.substring(5)
        }
        if (service && service.iconUrl && service.iconUrl.substring(0, 5) === 'http:') {
          service.iconUrl = 'https:' + service.iconUrl.substring(5)
        }
        return service
      })
    commit('setAllServiceList', allServiceList)
    return allServiceList
  },
  resetFilteredServiceData ({ commit }) {
    commit('setAllServiceList', [])
    commit('setStarServiceList', [])
  }
}
